#cooking {
  margin: 0;
  padding: 0;
}

#cooking-image {
  float: right;
}

#calories-button {
  display: block;
  width: 60%;
  min-width: fit-content;
  padding-top: 5px;
  margin: 5px auto;
  font-family: "Gelasio", "PT Serif";
  font-weight: bold;
  font-size: 22px;
}

#cooking-servings-div {
  text-align: center;
  font-family: "Girassol", 'Coolvetica';
  font-size: 24pt;
  font-weight: normal;
}
#cooking-float-div {
  width: 40%;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 197, 240, 0.85);
  border: #FF6090 double 5px;
  border-radius: 20px;
  padding: 10px;
}
@media(max-width: 800px) {
  #cooking-float-div {
    position: static;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

#cooking-servings {
  width: 80px;
  font-family: "Girassol", 'Coolvetica';
  font-size: 24pt;
  font-weight: bold;
  margin-left: 15px;
}
#cooking-tiny-serving-button {
  display: block;
  margin: auto;
  font-family: "Meie Script", "Bikham";
  font-size: 20pt;
  font-weight: bold;
}

@media(max-width: 600px) {
  #calories-button {
    font-size: 14px;
  }
  #cooking-tiny-serving-button {
    font-size: 12pt;
  }
}
