.App {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #000000;
}

header {
  width: 100%;
  height: 25%;
  margin: 0;
  padding: 0;
}

h2 {
  color: #400038;
  font-family: 'Girassol', 'Coolvetica', serif;
  font-weight: 800;
  font-size: 24pt;
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #000000;
  width: 100%;
  height: 75%;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#banner {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#banner_gradient {
  padding: 0;
  width: 100%;
  height: 25%;
  position: relative;
  top: 75%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

#title_box {
  width: 100%;
  position: relative;
  top: -40px;
}

#title {
  font-family: 'Meie Script', 'Bikham', cursive;
  width: max-content;
  max-width: 100%;
  margin: auto;
  font-size: 60pt;
  font-weight: bold;
  color: #FFA0A0;
  cursor: pointer;
}

#tagline, #tagline-tr {
  font-size: .8em;
  font-style: italic;
  color: white;
  padding-left: 20%;
  height: 0;
  margin-top: -5px;
}

#tagline {
  font-family: 'Meie Script', 'Bikham', cursive;
  font-size: 18pt;
}

#tagline-tr {
  font-family: 'Times New Roman';
  font-style: italic;
}

#options-menu {
  width: 100%;
  margin-top: 50px;
  margin-bottom: -30px;
  text-align: center;
}

#comingsoon {
  width: 100%;
  text-align: center;
  font-size: 24pt;
  font-weight: 800;
  font-family: 'Lobster', cursive;
  padding-bottom: 60px;
}
#comingsoon #awesome {
  font-size: 36pt;
  color: blue;
  font-family: 'Girassol', 'Coolvetica', cursive;
}

@media(max-width: 800px) {
  #title {
    font-size: 36pt;
  }
  #tagline {
    padding-left: 12%;
    font-size: 12pt;
  }
}
