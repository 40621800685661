.chlang {
  background: none;
  border: none;
  padding: 0;
  color: #005890;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
