.window {
  width: 96%;
  margin: 5px 1%;
  padding: 10px 1%;
  outline: #FF6090 double 5px;
}
.window-header {
  color: #400038;
  font-family: 'Lobster', cursive;
  font-size: 26pt;
  width: fit-content;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
.window-box {
  box-shadow: rgba(0,0,0,0.2) 0px 2px 3px, inset rgba(0,0,0,0.2) 0px -1px 2px;
  border-radius: 20px;
  background-color: #FFC5F0;
  padding: 10px;
  text-align: left;
}
.window-description {
  margin: 0;
  padding: 5px;
  color: black;
  font-size: 16pt;
  line-height: 1.25em;
  font-family: 'Gelasio', "PT Serif", serif;
  font-weight: normal;
  text-align: justify;
}

.window-image {
  width: 25%;
  height: auto;
  float: right;
  margin: 5px;
  padding: 0;
  border-radius: 5%;
}
.window-block-image {
  width: 35%;
  height: auto;
  display: block;
  margin: 5px auto;
  border-radius: 5px;
}

@media(max-width: 1200px) {
  .window-block-image {
    width: 50%;
  }
}
@media(max-width: 800px) {
  .window-image {
    width: 80%;
    padding: 10px 10%;
    float: none;
  }
  .window-block-image {
    width: 90%;
  }
}
