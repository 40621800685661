@media (max-width: 800px) {
  .read-more {
    font-size: 0px;
    margin: 0;
    padding: 0;
  }
  .read-more > * {
    display: none;
  }
  .read-more::before {
    color: #7078FF;
    font-size: 14pt;
    cursor: pointer;
  }
  .read-more.lang-en::before {
    content: "[Read more]";
  }
  .read-more.lang-es::before {
    content: "[Leer más]";
  }
  .read-more.lang-ru::before {
    content: "[Прочитать больше]";
  }
  .read-more.lang-pt::before {
    content: "[Ler mais]";
  }
  .read-more.lang-sw::before {
    content: "[Ongeza maandishi]";
  }
}
.read-more.alwayshide {
  font-size: 0px;
  margin: 0;
  padding: 0;
}
.read-more.alwayshide > * {
  display: none;
}
.read-more.alwayshide::before {
  color: #7078FF;
  font-size: 14pt;
  cursor: pointer;
}
.read-more.alwayshide.lang-en::before {
  content: "[Read more]";
}
.read-more.alwayshide.lang-es::before {
  content: "[Leer más]";
}
.read-more.alwayshide.lang-ru::before {
  content: "[Прочитать больше]";
}
.read-more.alwayshide.lang-pt::before {
  content: "[Ler mais]";
}
.read-more.alwayshide.lang-sw::before {
  content: "[Ongeza maandishi]";
}

.read-less {
  color: #7078FF;
  font-size: 14pt;
  cursor: pointer;
  width: fit-content;
}
