#preview-image-container {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  text-align: center;
}
#preview-title-container {
  position: fixed;
  top: 20px;
  left: 0;
  width: 100%;
}
#preview-title {
  width: 40%;
  min-width: min-content;
  margin: auto;
  color: #FFCFF0;
  font-family: 'Girassol', "PT Serif", serif;
  background-color: black;
  border: white 3px solid;
  border-radius: 25px;
  padding: 5px;
}
#preview-description {
  position: fixed;
  bottom: 20px;
  left: 15%;
  width: 70%;
  margin: 0;
  color: #FFCFF0;
  font-family: "Cormorant", serif;
  font-weight: 500;
  background-color: black;
  border: white 3px solid;
  border-radius: 25px;
  padding: 0;
  text-align: justify;
}
#preview-description p {
  margin: 5px 10px;
}
#preview-image {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: auto;
  height: 80%;
  outline: white 10px double; 
}

#folder-name {
  display: inline-block;
  width: fit-content;
}

#up-folder-button {
  display: inline-block;
  width: fit-content;
  height: auto;
  margin-right: 20px;
  cursor: pointer;
}

.folder-description {
  font-family: "Cormorant", serif;
  font-size: 14pt;
}

.photo-tiny-div {
  display: inline-block;
  margin: 10px 20px; 
  padding: 0;
  cursor: pointer;
}
.photo-tiny-div.folder {
  outline: #FFA090 5px solid;
}
.photo-tiny-folder {
  margin-left: -50px;
}
.photo-tiny-div.folder .photo-tiny {
  margin-bottom: -5px;
}

@media(max-width: 800px) {
  #preview-image {
    width: 90%;
    height: auto;
  }
}

@media(min-width: 1200px) {
  #preview-description p {
    font-size: 16pt;
  }
}

