.ingredient-img-div img {
  display: inline;
  width: 50px;
  height: 50px;
  padding: auto;
  vertical-align: middle;
  border-radius: 20px;
}
.ingredient-img-div {
  display: inline-block;
  width: 25%;
  text-align: center;
}

.ingredient-div {
  display: inline-block;
  width: 73%;
  vertical-align: middle;
  margin-left: 2%;
  font-size: 12pt;
  line-height: 1em;
}

.recipe-ingredient-guide p {
  margin-top: 2px;
  color: #606060;
  font-size: 11pt;
}
