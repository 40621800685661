.option {
  min-width: 180px;
  text-align: center;
  font-weight: bold;
  color: #FF6080;
  font-family: 'Girassol', 'Coolvetica', cursive;
  display: inline-block;
}
.option-text {
  padding: 10px;
  margin: auto;
  width: max-content;
  cursor: pointer;
}
