.pub {
  margin: 10px;
  padding: 10px;
  padding-bottom: 0;
  border: #400038 3px double;
  border-radius: 20px;
}
.pub-title {
  color: #400038;
  font-family: 'Girassol', 'Coolvetica', serif;
  font-weight: 800;
  font-size: 20pt;
  margin: 0;
  padding: 0;
  display: block;
}
.pub-title a {
  text-decoration: none;
}
.pub-description {
  font-size: 12pt;
  display: flex;
  text-align: left;
}
.pub-info-container {
  width: 100%;
  padding-bottom: 5px;
  font-style: italic;
  font-size: 16pt;
  font-family: 'Crimson Text', 'PT Serif', serif;
}
.pub-info {
  width: 50%;
  height: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}
.pub-image-container {
  display: inline-block;
  width: 40%;
  maring: 0;
  padding: 10px 0;
  text-align: center;
}
.pub-body {
  display: inline-block;
  width: 60%;
  margin: 0;
  padding: 0;
  color: black;
  font-family: 'Gelasio', serif;
  vertical-align: top;
}
@media(max-width: 1000px) {
  .pub-image-container, .pub-body {
    width: 100%;
  }
}

.pub-image {
  width: auto;
  max-width: 100%;
  height: 160px;
  margin: 0;
  padding: 0;
  border-radius: 10px;
}
