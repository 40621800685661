#blog-title {
  color: #400038;
  text-align: center;
  font-size: 20pt;
  font-family: "Lobster", cursive;
}

#main-post {
  margin: 0 auto;
  margin-top: 15px;
  width: 95%;
  border: #400038 2px solid;
  border-radius: 5px;
  text-align: left;
  font-family: "Gelasio", serif;
}

#main-post-title {
  width: 100%;
  text-align: center;
}
#main-post-subtitle {
  width: 100%;
  text-align: center;
  color: #400038;
  font-family: 'Meie Script', 'Bikham', cursive;
  font-size: 20pt;
  margin-top: 0;
  padding-top: 0;
}
#main-post-img {
  dislay: block;
  width: 30%;
  height: auto;
  margin: 10px 35%;
  border-radius: 5%;
}
#main-post-location {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  color: black;
  font-family: "Crimson Text", "PT Serif", serif;
  font-size: 18pt;
}
#main-post-location li {
  display: inline-block;
  margin: 0 20px;
  padding: 1px;
  list-style-type: none;
}
#main-post-text {
  padding: 0 10px;
}
.to-top {
  font-size: 14pt;
  text-decoration: none;
  padding: 5px;
}

.post-listing {
  width: 20%;
  display: inline-block;
  text-align: center;
}
.post-listing-inner-div {
  width: 90%;
  margin: 15px;
  border: #400038 1px dashed;
  border-radius: 15px;
}
.post-listing-title {
  color: #400038;
  font-family: 'Girassol', 'Coolvetica', serif;
  font-size: 14pt;
  text-align: center;
}
.post-listing-location {
  color: black;
  font-weight: 700;
  font-family: "Crimson Text", "PT Serif", serif;
  text-align: center;
  min-height: 1em;
}

.post-listing-img {
  width: 50%;
  height: auto;
  margin: auto;
  margin-bottom: 3px;
  display: block;
}

@media(max-width: 1200px) {
  #main-post-img {
    width: 50%;
    margin: 8px 25%;
  }
  .post-listing {
    width: 25%;
  }
  .post-listing img {
    width: 60%;
  }
}

@media(max-width: 800px) {
  #main-post-img {
    width: 70%;
    margin: 5px 15%;
  }
  .post-listing {
    width: 33.33%;
  }
  .post-listing img {
    width: 70%;
  }
}

@media(max-width: 600px) {
  .post-listing {
    font-size: 13pt;
    width: 50%;
  }
}
