#lang-picker-div {
  width: 200px;
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: rgba(255, 197, 240, 0.85);
  border: #FF6090 double 5px;
  border-radius: 20px;
  padding: 10px;
}
#lang-picker-div h1 {
  min-width: max-content;
  color: #400038;
  font-family: 'Girassol', 'Coolvetica', serif;
  font-size: 18pt;
  width: 100%;
  text-align: left;
  margin: 0;
}
#lang-picker {
  min-width: max-content;
  margin: 0 auto;
  margin-top: 3px;
  padding: 5px;
  font-family: 'Lobster', serif;
  font-size: 12pt;
  text-align: left;
  color: #400038;
  background-color: white;
}
