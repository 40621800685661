.recipe {
  width: 50%;
  margin: 5px 0;
  text-align: center;
  vertical-align: top;
}
.recipe-active {
  width: 50%;
  background-color: white;
  border-radius: 25px;
}
.recipe-name {
  width: fit-content;
  cursor: pointer;
  margin: 10px auto;
  padding: 5px 100px;
  background-color: white;
  border-radius: 15px;
  text-align: center;
}
.recipe-name img {
  margin: 0 20px;
  width: 200px;
  height: 150px;
}
.recipe-kcals {
  font-family: "Crimson Text", "PT Serif";
  margin: 3px;
}
.recipe-details {
  font-family: "Gelasio", "PT Serif";
}
.recipe-details h3 {
  font-family: "Lobster";
  font-size: 20pt;
  margin: 0;
}
.recipe-description {
  font-family: "Crimson Text";
  font-size: 14pt;
  margin: 10px 20%;
  padding: 5px 15px;
  text-align: justify;
  line-height: 1.1em;
  border: #888888 1px dashed;
}
.recipe-description p {
  margin: 5px 0;
  padding: 0;
}

.recipe-steps, .recipe-ingredients {
  display: inline-block;
  width: 46%;
  text-align: left;
  vertical-align: top;
  padding: 0 1%;
  font-family: "Gelasio", "PT Serif";
  font-size: 14pt;
}
.recipe-steps li, .recipe-ingredients li {
  margin-bottom: 5px;
}
.recipe-steps p, .recipe-ingredients p {
  margin: 0;
}
.recipe-ingredients ul {
  list-style-type: none;
  padding-left: 0;
}
.recipe-step-pointer, .recipe-ingredient-guide {
  font-size: smaller;
  margin: 0;
}
.recipe-step-optional {
  margin: 0;
  display: inline;
}

@media (max-width: 1200px) {
  .recipe-name {
    padding: 5px 60px;
  }
}
@media (max-width: 1000px) {
  .recipe-name {
    padding: 5px 20px;
  }
}
@media (max-width: 800px) {
  .recipe {
    width: 100%;
  }
}

